<template>
  <el-button :loading="importBtnLoading" :type="btnType" :disabled="isDisabled" @click="importBtn()">{{ exportName }}</el-button>
</template>

<script>
import { ExcelExport } from '@/api/scm-api'
export default {
  name: 'ExcelExportFile',
  props: {
    params: {
      type: [Object],
      default: () => ''
    },
    exportKey: {
      type: [String, Object],
      default: () => ''
    },
    exportName: {
      type: String,
      default: '导出'
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    },
    btnType: {
      type: String,
      require: false,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  // mounted() {
  //   this.$store.commit('importBtn', null)
  // },
  data() {
    return {
      importBtnLoading: false
    }
  },
  methods: {
    // 点击导出按钮导出所需数据信息
    importBtn() {
      const data = {}
      data.params = JSON.stringify(this.params)
      // data.language = this.$store.getters.language
      data.exportKey = this.exportKey
      this._ExcelExport(data)
    },
    async _ExcelExport(query) {
      try {
        this.importBtnLoading = true
        const { msg, code } = await ExcelExport(query)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
        } else {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this.importBtnLoading = false
        this.$emit('close', false)
      } finally {
        this.importBtnLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
