<template>
  <!-- 审批流管理 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="140px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item :label="$t('title.Approvalnumber')">
              <el-input v-model="form.approvalCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号">
              <el-input v-model="form.relationCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="审批类型">
              <el-select v-model="form.approvalType" :placeholder="$t('page.selectPlaceholder')">
                <el-option label="采购计划" value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.status')">
              <el-select v-model="form.status" :placeholder="$t('page.selectPlaceholder')">
                <el-option label="运营审批中" value="0" />
                <el-option label="运营总监审批中" value="1" />
                <el-option label="计划部批中" value="2" />
                <el-option label="财务部批中" value="3" />
                <el-option label="采购审批中" value="4" />
                <el-option label="审批通过" value="9" />
                <el-option label="审批拒绝" value="10" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审批角色">
              <el-input v-model="form.approvalRole" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-row :span="24" class="mb-3">
      <el-col :span="24">
        <ImportButton export-key="scm_approval" :params="form" />
      </el-col>
    </el-row>
    <el-table
      ref="multipleTable"
      class="mb-3"
      :data="tableDatas"
      max-height="550px"
      :header-cell-style="{background:'#fafafa'}"
    >
      <el-table-column
        type="selection"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="approvalCode"
        label="审批单号"
        width="170"
        sortable
      />
      <!-- <template slot-scope="scope">
          <router-link :to="{ path: '/stock-out/ToC/shippinginfo/details', query: { transportId: scope.row.transportId ,outboundCode: scope.row.outboundCode}}" style="color:#1890ff;">{{ scope.row.transportId }}</router-link>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="relationCode"
        label="关联单号"
        align="center"
        width="170"
        sortable
      />
      <el-table-column
        prop="approvalType"
        label="审批类型"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <p v-if="scope.row.approvalType=='1'">采购计划</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <p v-if="scope.row.status=='0'">运营审批中</p>
          <p v-if="scope.row.status=='1'">运营总监审批中</p>
          <p v-if="scope.row.status=='2'">计划部批中</p>
          <p v-if="scope.row.status=='3'">财务部批中</p>
          <p v-if="scope.row.status=='4'">采购审批中</p>
          <p v-if="scope.row.status=='9'">审批通过</p>
          <p v-if="scope.row.status=='10'">审批拒绝</p>

        </template>
      </el-table-column>

      <el-table-column
        prop="approvalRole"
        label="审批角色"
        align="center"
        sortable
      />
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
        sortable
      />
      <el-table-column
        fixed="right"
        :label="$t('page.operate')"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <!-- <router-link }" style="color:#1890ff;"> -->
          <el-button type="text" size="small" @click="adddetail(scope.row)">
            <p v-if="scope.row.show">查看</p>
          </el-button>
          <!-- </router-link> -->
          <!-- <router-link  :to="{ path: '/stock-out/ToC/shippinginfo/details', query: { transportId: scope.row.transportId ,outboundCode: scope.row.outboundCode}}" style="color:#1890ff;"> {{ $t('title.ToCShipping') }}</router-link> -->
        </template>
      </el-table-column>

    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        @pagination="_approvalpage"
      />
    </div>
  </div>
</template>
<script>
import { approvalpage } from '@/api/scm-api'
import Pagination from '@/components/Pagination'
import ImportButton from '@/components/ImportButton'

export default {
  components: { Pagination, ImportButton },
  data() {
    return {
      showRow: false, // 切换显示input
      tableDatas: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      form: {
      },
      status: '',
      name: ''
      // shows: false
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      return Object.assign({}, this.pager, this.form)
    }
  },
  mounted() {
    this.name = this.$route.name
    this.status = this.$route.meta.status
    this._approvalpage()
  },
  methods: {
    // 点击查询获取信息
    queryClick(page) {
      if (page) this.pager.current = 1
      this._approvalpage(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this.queryClick(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 默认查询
    async _approvalpage(pager) {
      try {
        this.TableLoading = true
        pager && Object.assign(this.pager, pager)
        const { datas } = await approvalpage(this.queryParameter)
        var myData = datas.records
        myData.map(v => {
          this.$set(v, 'name', this.name)
          return v
        })
        myData.map(e => {
          e.show = false
          if (this.name === 'ApprovalFlowMGMT1' && e.status === 0) {
            e.show = true
          }
          if (this.name === 'ApprovalFlowMGMT2' && e.status === 1) {
            e.show = true
          }
          if (this.name === 'ApprovalFlowMGMT3' && e.status === 2) {
            e.show = true
          }
          if (this.name === 'ApprovalFlowMGMT4' && e.status === 3) {
            e.show = true
          }
          if (this.name === 'ApprovalFlowMGMT5' && e.status === 4) {
            e.show = true
          }
        })
        this.tableDatas = myData
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },
    adddetail(row) {
      this.$router.push({
        path: name + 'added',
        append: true,
        query: { 'relationCode': row.relationCode, 'approvalCode': row.approvalCode, 'status': row.status }
      })
    },
    //  列表分页方法
    handleSizeChange(val) {
      this.pager.size = val
      this._approvalpage(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._approvalpage(this.queryParameter)
    }

  }
}
</script>
<style lang="scss">
.create-overseas .el-form-item__error {
  width: 86%;
  text-align: right;
}
</style>
